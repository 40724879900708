<template>
	<div class="account-pages my-5 pt-5">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-md-8 col-lg-6 col-xl-5">
					<div class="card overflow-hidden">
						<div class="bg-primary">
							<div class="text-primary text-center p-4">
								<h5 class="text-white font-size-20" id="loginTitle">Email Verification Failed</h5>
								<a href="/" class="logo logo-admin">
									<img src="@/assets/images/logo-sm.png" height="50" alt="logo" />
								</a>
							</div>
						</div>
						<div class="card-body p-4">
							<div class="pt-3 pl-3 pr-3">
								<b-alert
									show
									class="mt-4 text-center"
									variant="danger"
								>We were unable to verify your email address.</b-alert>
							</div>
							<div class="row">
								<div class="col-sm-12 text-center">
									<b-button variant="primary" class="w-md" @click="$router.push('/login')">
										<span>Login</span>
									</b-button>
								</div>
							</div>
						</div>
						<!-- end card-body -->
					</div>
					<!-- end card -->
					<MiniFooter />
				</div>
				<!-- end col -->
			</div>
			<!-- end row -->
		</div>
	</div>
</template>

<script>
import appConfig from "@/app.config";
import MiniFooter from '@/components/account/mini-footer';

export default {
	name: 'EmailVerificationFailure',
	page: {
		title: "Email Verification Failed",
		meta: [{ name: "description", content: appConfig.description }]
	},
	components: {
		MiniFooter,
	},
};
</script>

